import type { SVGProps } from 'react';

const FactoryFloorManagementSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 6C14 5.44772 13.5523 5 13 5C12.4477 5 12 5.44772 12 6C12 6.27614 11.7761 6.5 11.5 6.5H10.5C9.11929 6.5 8 7.61929 8 9C8 9.55228 8.44772 10 9 10C9.55228 10 10 9.55228 10 9C10 8.72386 10.2239 8.5 10.5 8.5H11.5C12.8807 8.5 14 7.38071 14 6ZM5 27L6.5 11H11V19.2391V27H5ZM13 19L20 14V16L27 11V12.5L20 17.5V19L27 14V27H22V23H16V27H13V19ZM20 12.5L13 17.5V16L20 11V12.5Z"
      fill="currentColor"
    />
  </svg>
);
export default FactoryFloorManagementSvgIcon;
